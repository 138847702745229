.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px 20px;
    h1 {
        display: flex;
        gap: 18px;
        justify-content: center;
        font-weight: 300;
        font-size: 50px;
        line-height: 1.2;
        margin: 0 auto 10px;
        b {
            font-weight: 500;
        }
        span {
            // flex: 1;
            &.logo {
                // flex: inherit;
                display: block;
                width: 40px;
                height: 40px;
                img {
                    width: 100%;
                }
            }
        }
    }

    .frame-3d {
        width: 300px;
        height: 300px;
        max-width: 100%;
        max-height: calc(100vw - 40px);
        margin: 0 auto;
        display: block;
        canvas{
            width: 100%!important;
            height: auto!important;
        }
        .loader{
            display: flex;
            height: 100%;
            width: 100%;
            justify-content: center;
            align-items: center;
        }
    }

    .filter-group {
        display: flex;
        gap: 32px;
        justify-content: center;
        align-items: flex-end;
        height: 60px;

        .form-check {
            display: flex;
            flex-direction: column;
            gap: 0;
            width: 60px;
            padding: 10px 12px;
            border-radius: 50px;
            box-sizing: border-box;
            transition: all 0.5s ease;

            &.bg-time {
                background: linear-gradient(180deg, #0191b6 0%, #f9d80d 100%);
            }
            &.bg-type {
                background: linear-gradient(180deg, #f04772 0%, #ffd068 100%);
            }
            &.bg-price {
                background: linear-gradient(180deg, #041c44 0%, #f64c32 100%);
            }

            &.expanded {
                padding-top: 8px;
                padding-bottom: 8px;
                gap: 12px;
                box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
                transform: translateY(-7px);
                input {
                    + svg {
                        height: 30px;
                        opacity: 1;
                        padding: 5px 0;
                    }
                }
            }

            label {
                display: flex;
                padding: 0;
                justify-content: center;
                align-items: center;

                input {
                    appearance: none;
                    position: absolute;
                    width: 0;
                    height: 0;

                    &:checked {
                        + svg {
                            height: 30px;
                            opacity: 1;
                            padding: 5px 0;
                        }
                    }

                    + svg {
                        cursor: pointer;
                        height: 0px;
                        padding: 0;
                        opacity: 0;
                        transition: all 0.5s ease;
                    }
                }
            }
        }
    }

    p {
        font-size: 12px;
        text-align: center;
        strong {
            font-size: 15px;
        }

        @media screen and (min-width: 768px) {
            font-size: 14px;
            strong {
                font-size: 18px;
            }
        }
    }
}
