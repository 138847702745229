@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500&display=swap");

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  height: 100vh;
  background: linear-gradient(232deg, #fff 0%, #d1daff 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;

  background-size: cover;
  font-size: 16px;
  line-height: 1.5;
  color: #222222;

  * {
    font-family: "Montserrat", sans-serif;
  }
}

.d-pc-none {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

footer {
  color: #989898;
  font-size: 10px;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 12px;
  }
}

.btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  text-decoration: none;
  gap: 5px;
  padding: 5px 20px;
  border-radius: 30px;
  border: 0;
  transition: all 0.4s ease-in-out;
  svg {
    max-height: 35px;
  }
  span {
    flex: 1;
  }
  background: linear-gradient(90deg, #f04772 0%, #ffd068 100%);
  background-size: 150% 100%;
  &.btn-secondary {
    background: linear-gradient(90deg, #0191b6 0%, #f9d80d 100%);
    background-size: 150% 100%;
  }

  &:hover {
    background-position: 100% 100%;
  }

  &:focus {
    outline: none;
  }

  &.rotate-icon svg {
    animation: rotateAnimation 0.8s linear infinite;
  }
}

.fade-in {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  &.delay-100 {
    transition-delay: 0.2s;
  }
  &.delay-200 {
    transition-delay: 0.2s;
  }
  &.delay-300 {
    transition-delay: 0.3s;
  }
  &.delay-400 {
    transition-delay: 0.4s;
  }
  &.delay-500 {
    transition-delay: 0.5s;
  }
}

.fade-in.show {
  opacity: 1;
}

@keyframes rotateAnimation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
