.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(5px);
}

.popup {
    width: 340px;
    border-radius: 20px;
    background-color: #ffffff;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    overflow: hidden;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);

    @media screen and (min-width: 768px) {
        width: 450px;
    }

    .popup-header {
        position: relative;
        img {
            width: 100%;
            display: block;
            user-select: none;
            pointer-events: none;
        }
        .close {
            appearance: none;
            color: #ffffff;
            position: absolute;
            right: 15px;
            top: 15px;
            width: 36px;
            height: 36px;
            border: 0;
            padding: 0;
            text-align: center;
            cursor: pointer;
            background: rgba(34, 34, 34, 0.5);
            border-radius: 50%;
            font-size: 20px;
            font-weight: bold;
            line-height: 36px;
            backdrop-filter: blur(5px);
            transition: all ease-in 0.2s;

            &:hover {
                background: rgba(34, 34, 34, 0.75);
                color: #fff2b3;
            }
        }
    }
    .popup-body {
        padding: 20px;
        transition: all ease-in 0.3s;
        @media screen and (min-width: 768px) {
            padding: 20px 25px 15px;
        }
        p {
            margin: 0;
        }
        .heading {
            display: flex;
            gap: 10px;
            h2 {
                margin-top: 0;
                margin-bottom: 10px;
                font-size: 18px;
                line-height: 1.3;
                flex: 1;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                @media screen and (min-width: 768px) {
                    margin-bottom: 20px;
                }
            }
            .ads {
                color: #f64c32;
                font-size: 10px;
                line-height: calc(18px * 1.3);
                margin: 0;

                @media screen and (min-width: 768px) {
                    font-size: 12px;
                }
            }
        }

        .address {
            text-align: left;
            font-size: 14px;
        }
    }
    .popup-footer {
        padding: 0 20px 20px;
        @media screen and (min-width: 768px) {
            padding: 10px 25px 25px
        }
        .btn-group {
            display: flex;
            gap: 20px;
            justify-content: center;
        }
    }
}
